export const getUserStats = async (userId) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/users/get-stats/${userId}`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        });

        if (!response.ok) {
            response.message = 'Get user stats failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const getAllUsersStats = async () => {
    try {
        const fetchUrl = `https://api.digi-youth.com/users/get-all-stats`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        });

        if (!response.ok) {
            response.message = 'Get all users stats failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}


export const setPartialGameStats = async (userId, gameStats) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/users/set-partial-stats`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, gameStats })
        });

        if (!response.ok) {
            response.message = 'Set partial game stats failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}
