const listNews = async () => {
    try {
        const fetchUrl = `https://api.digi-youth.com/news/get`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        })

        if (!response.ok) {
            response.message = 'Get news failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
};

const getNewsByUrlPath = async (urlPath) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/news/get/${urlPath}`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        })

        if (!response.ok) {
            response.message = 'Get news failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

const deleteNewsByUrlPath = async (urlPath) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/news/delete/${urlPath}`;
        const response = await fetch(fetchUrl, {
            method: 'DELETE',
        })

        if (!response.ok) {
            response.message = 'Delete news failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

const addNews = async (heading, previewText, urlPath, description, imgPath) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/news/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    heading: heading,
                    previewText: previewText,
                    urlPath: urlPath,
                    description: description,
                    imgPath: imgPath
                }
            )
        })

        if (!response.ok) {
            response.message = 'Add news failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export { listNews, getNewsByUrlPath, deleteNewsByUrlPath, addNews };
