// API endpoints for missions

export const addMission = async (mission) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mission)
        });

        if (!response.ok) {
            response.message = 'Adding mission failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const getAllMissions = async () => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions`;
        const response = await fetch(fetchUrl, {
            method: 'GET'
        });

        if (!response.ok) {
            response.message = 'Get all missions failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const getSpecificMission = async (missionId) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/${missionId}`;
        const response = await fetch(fetchUrl, {
            method: 'GET'
        });

        if (!response.ok) {
            response.message = 'Get specific mission failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const updateMissionApproval = async (missionId, missionApproved) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/${missionId}/approval`;
        const response = await fetch(fetchUrl, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ missionApproved })
        });

        if (!response.ok) {
            response.message = 'Update mission approval failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const rejectMission = async (missionId, missionApproved, approvalFeedback) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/${missionId}/reject`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ missionApproved, approvalFeedback })
        });

        if (!response.ok) {
            response.message = 'Reject mission failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const uploadMissionImage = async (image) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/image/upload`;
        const formData = new FormData();
        formData.append('image', image);
        const response = await fetch(fetchUrl, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            response.message = 'Upload mission image failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const getUserMissions = async (userId) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/get-user-missions/${userId}`;
        const response = await fetch(fetchUrl, {
            method: 'GET'
        });

        if (!response.ok) {
            response.message = 'Get user missions failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const updateMission = async (missionId, mission) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/missions/${missionId}/update`;
        const response = await fetch(fetchUrl, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mission)
        });

        if (!response.ok) {
            response.message = 'Update mission failed';
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}