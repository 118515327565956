import React, {useEffect, useRef, useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
import Box from "@mui/material/Box";
import {TextField, Typography} from "@mui/material";
import {HeadingText} from "../../components/styles/Typography.styled";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {getTeam, deleteTeamMember, addTeamMember} from "../../utils/api/team";
import {v4 as uuidv4} from "uuid";
import Paper from "@mui/material/Paper";
import {ButtonStyled} from "../../components/styles/UserButtons.styled";
import {addNews} from "../../utils/api/news";
import {FlexContainer} from "../../components/styles/Containers.styled";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminPanelTeam() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <LeftNavigation/>
            <AdminRoot>
                <HeadingText variant="h5">Team</HeadingText>
                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="List Team Members" {...a11yProps(0)} />
                            <Tab label="Add Team Members" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ListTeamMembers />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddTeamMembers />
                    </TabPanel>
                </Box>
            </AdminRoot>
        </>
    )
}

function ListTeamMembers() {
    const [teamMembers, setTeamMembers] = useState([])

    useEffect(() => {
        getTeam().then((res) => {
            const { teamMembers } = res
            console.log(teamMembers)
            setTeamMembers(teamMembers)
        })
    }, [])

    const handleDelete = (id) => {
        deleteTeamMember(id)
        alert('Team member deleted')
    }

    return (
        <>
            {
                teamMembers.map((teamMember) => {
                    return (
                        <Paper sx={{ mb: 5, width: '50%', p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }} elevation={1} key={uuidv4()}>
                            <div>Name:</div>
                            <strong>{teamMember.firstName + ' ' + teamMember.lastName}</strong>
                            <img src={teamMember.imgPath} alt={teamMember.heading} style={{ height: '200px', marginTop: '1rem' }}/>
                            <ButtonStyled onClick={() => handleDelete(teamMember._id)} sx={{ mt: 2, backgroundColor: '#f50057', '&:hover': {backgroundColor: 'red',} }}>Delete</ButtonStyled>
                        </Paper>
                    )
                })
            }
        </>
    )
}

function AddTeamMembers() {
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imgPath, setImgPath] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const uploadImageRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const jobTitleRef = useRef(null);
    const companyNameRef = useRef(null);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://api.digi-youth.com/team/image/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('Image uploaded successfully!');
                const { imagePath } = await response.json();
                setImgPath(imagePath)
                console.log(imagePath)
                setIsImageUploaded(true)
            } else {
                console.error('Error uploading image:', response.statusText);
                setIsImageUploaded(false)
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleAddTeamMember = async () => {
        if(!setIsImageUploaded) return alert('Something went wrong! Image was not uploaded...')

        const imagePath = `https://api.digi-youth.com/public${imgPath}`
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const jobTitle = jobTitleRef.current.value;
        const companyName = companyNameRef.current.value;

        addTeamMember(firstName, lastName, jobTitle, companyName, imagePath).then((res) => {
            if (res.success) {
                alert('Team member added successfully!')
                uploadImageRef.current.value = '';
                firstNameRef.current.value = '';
                lastNameRef.current.value = '';
                jobTitleRef.current.value = '';
                companyNameRef.current.value = '';
                setIsImageUploaded(false)
                setImgPath('')

            } else if (res.error && res.error.errors) {
                const errorMessages = Object.values(res.error.errors).map(error => error.message);
                setErrorMessage(errorMessages.join(', '));
            }
        })
    }

    return (
        <FlexContainer sx={{ width: '50%' }}>
            <TextField inputRef={firstNameRef} label="First name" variant="outlined" />
            <TextField inputRef={lastNameRef} label="Last name" variant="outlined" />
            <TextField inputRef={jobTitleRef} label="Job title" variant="outlined" />
            <TextField inputRef={companyNameRef} label="Company name" multiline rows={4} variant="outlined" />
            <input ref={uploadImageRef} type="file" accept="image/*" onChange={handleImageUpload} />
            <ButtonStyled sx={{ mt: 2 }} onClick={() => handleAddTeamMember()}>Add</ButtonStyled>
            {errorMessage && <strong style={{ color: 'red' }}>{errorMessage}</strong>}
        </FlexContainer>
    )
}
