import React, {useRef, useState} from 'react'
import { v4 as uuid } from 'uuid'
import {
    ButtonsBox,
    CenteredText, MissionAuthorBox,
    MissionCard, MissionDescriptionText,
    MissionDetailsBox,
    MissionsContainer, PlayMissionButton, StyledImage
} from "../styles/simulationGame/Missions.styled";
import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {selectMission} from "../../slices/gameSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {rejectMission, updateMissionApproval} from "../../utils/api/mission";
import theme from "../../components/styles/theme"

const Missions = ({ missions, componentType }) => {
    const { t } = useTranslation('ns1');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handlePlayMission = (mission) => {
        console.log(mission)
        dispatch(selectMission(mission))
        navigate('/simulation-game/lobby')
    }

    const handleApproveMission = (mission) => {
        const id = mission._id
        updateMissionApproval(id, true)
        alert('Mission approved! Refresh admin panel to see changes.')
    }

    const handleRejectMission = (mission) => {
        setOpen(true)
    }

    const handleEditMission = (mission) => {
        dispatch(selectMission(mission))
        navigate('/simulation-game/edit-mission')
    }

    return (
        <MissionsContainer>
            {missions?.map((mission, index) => (
                <MissionCard elevation={5} key={mission._id}>
                    <StyledImage
                        src={`https://api.digi-youth.com/public/${mission.imagePath}`}
                        alt={mission.title}
                        index={index}
                    />
                    <MissionDetailsBox>
                        <Box>
                            <CenteredText
                                sx={{
                                    color: index % 3 === 0 ? '#FC6A03'
                                    : index % 2 === 0 ? '#00d5c0'
                                    : '#236ef5'
                            }}
                                variant="h6"
                                component="h5"
                                fontWeight="bold">
                                Mission {mission.sequentialId}
                            </CenteredText>
                            <CenteredText variant="h5" component="h5" fontWeight="bold">
                                {mission.title}
                            </CenteredText>
                        </Box>
                        { (componentType === 'user' && mission.approvalFeedback) ?
                            <>
                                <Typography color="red" variant="h6" component="h6">
                                    Your mission has been returned to you to make changes! Please, review the feedback below:
                                </Typography>
                                <Typography variant="h6" component="h6">
                                    {mission.approvalFeedback}
                                </Typography>
                            </>
                        :
                            <MissionDescriptionText variant="h6" component="h6">
                                {mission.description}
                            </MissionDescriptionText>
                        }
                        {/*{ !mission.approvalFeedback &&*/}
                        {/*    <Typography variant="h6" component="h6">*/}
                        {/*        {mission.description}*/}
                        {/*    </Typography>*/}
                        {/*}*/}
                        <ButtonsBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <PlayMissionButton variant="contained" color="secondary" onClick={() => handlePlayMission(mission)}>
                                <Typography fontWeight="bold">{t('simulation_game_play_text')}</Typography>
                            </PlayMissionButton>
                            {
                                componentType === 'approved' &&
                                <MissionAuthorBox>
                                    <Typography>Author: </Typography>
                                    <Typography fontWeight="bold" sx={{ color: index % 3 === 0 ? '#FC6A03'
                                            : index % 2 === 0 ? '#00d5c0'
                                                : '#236ef5'
                                    }}>{mission.authorName}</Typography>
                                </MissionAuthorBox>
                            }
                            { componentType === 'admin' &&
                                <>
                                    <Button sx={{ width: '25%' }} variant="outlined" color="primary" onClick={() => handleApproveMission(mission)}>
                                        <Typography fontWeight="bold">{t('simulation_game_approve_text')}</Typography>
                                    </Button>
                                    <Button sx={{ width: '25%' }} variant="contained" color="secondary" onClick={() => handleRejectMission(mission)}>
                                        <Typography fontWeight="bold">{t('simulation_game_reject_text')}</Typography>
                                    </Button>
                                    <RejectModal open={open} onClose={() => setOpen(false)} mission={mission}/>
                                </>
                            }
                            { componentType === 'user' &&
                                <>
                                    <Button sx={{ width: '25%' }} variant="contained" color="primary" onClick={() => handleEditMission(mission)}>
                                        <Typography fontWeight="bold">{t('simulation_game_edit_text')}</Typography>
                                    </Button>
                                </>
                            }
                        </ButtonsBox>
                    </MissionDetailsBox>
                </MissionCard>
            ))}
        </MissionsContainer>
    )
}

const RejectModal = ({ open, onClose, mission }) => {
    const rejectReasonRef = useRef()

    const style = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const handleRejectMission = () => {
        onClose();
        alert('close!')
        rejectMission(mission._id, false, rejectReasonRef.current.value)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Enter a reject reason
                </Typography>
                <TextField
                    id="outlined-multiline-static"
                    label="This reason will be seen by the mission author"
                    multiline
                    rows={4}
                    inputRef={rejectReasonRef}
                />
                <Button variant="contained" color="secondary" onClick={handleRejectMission}>Reject</Button>
            </Box>
        </Modal>
    )
}

export default Missions