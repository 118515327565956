export const getSessions = async () => {
    try {
        const fetchUrl = `https://api.digi-youth.com/sessions/get`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        })

        if (!response.ok) {
            response.message = 'Get sessions failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const addSession = async (earnedBadges, maxBadges, timePlayed, createdAt, missionNumber) => {
    try {
        const fetchUrl = `https://api.digi-youth.com/sessions/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ earnedBadges, maxBadges, timePlayed, createdAt, missionNumber })
        })

        if (!response.ok) {
            response.message = 'Add session failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}