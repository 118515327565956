import React, {useEffect, useRef, useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
import {HeadingText, Text} from "../../components/styles/Typography.styled";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import useFetch from "../../utils/useFetch.js";
import { v4 as uuidv4 } from 'uuid';
import { useTable, usePagination, useFilters, useSortBy, useGlobalFilter } from 'react-table';
import { Modal, Button } from '@mui/material';
import {ModalContent, StyledModal} from "../../components/styles/Modal.styled";
import {FlexContainer} from "../../components/styles/Containers.styled";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminPanelTranslations() {
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <LeftNavigation/>
            <AdminRoot>
                <HeadingText variant="h5">Translations</HeadingText>
                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="List translations" {...a11yProps(0)} />
                            <Tab label="Add new translation" {...a11yProps(1)} />
                            <Tab label="Available languages" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ListTranslations/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddTranslation />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <AvailableLanguages/>
                    </TabPanel>
                </Box>
            </AdminRoot>
        </>
    )
}

function AddTranslation() {
    const { data: languageChoices} = useFetch('https://api.digi-youth.com/translations/languages/get', {
        method: 'GET',
    })

    const translationKeyRef = useRef();
    const translationValueRef = useRef();
    const [chosenLanguage, setChosenLanguage] = useState('en')

    const handleAddTranslation = () => {
        const translationKey = translationKeyRef.current.value;
        const translationValue = translationValueRef.current.value;

        fetch(`https://api.digi-youth.com/translations/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                translationKey: translationKey.replace(/\./g, '_').toLowerCase(),
                translationValue,
                chosenLanguage,
            }),
        }).then((response) => {
            if (response.status === 200) {
                alert('Translation added successfully');
                translationKeyRef.current.value = '';
                translationValueRef.current.value = '';
            } else {
                alert('Something went wrong');
            }
        })
    }

    return (
        <FlexContainer sx={{ width: '35%' }}>
            <Text>Add new translation</Text>
            <label>key</label>
            <input ref={translationKeyRef}></input>
            <label>value</label>
            <input ref={translationValueRef}></input>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={chosenLanguage} onChange={(e) => setChosenLanguage(e.target.value)} defaultValue="en">
                {languageChoices?.languages?.map((language) => (
                    <MenuItem value={language} key={uuidv4()}>{language}</MenuItem>
                ))}
            </Select>
            <Button variant="outlined" onClick={handleAddTranslation}>
                Save
            </Button>
        </FlexContainer>
    )
}

function ListTranslations() {
    const [chosenLanguage, setChosenLanguage] = useState('en')

    const { data: languageChoices} = useFetch('https://api.digi-youth.com/translations/languages/get', {
        method: 'GET',
    })

    const { data: translations } = useFetch(`https://api.digi-youth.com/translations/get?language=${chosenLanguage ? chosenLanguage : 'en'}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })

    const handleChange = (event) => {
        setChosenLanguage(event.target.value);
    }

    return (
        <>
            <Text>Choose a language</Text>
            <FormControl sx={{ width: '100px' }}>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={chosenLanguage} onChange={handleChange} defaultValue="en">
                    {languageChoices?.languages?.map((language) => (
                        <MenuItem value={language} key={uuidv4()}>{language}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Text>Search</Text>
            <TranslationTable chosenLanguage={chosenLanguage} translations={translations ? translations?.translations : {}}/>
        </>
    )
}

function TranslationTable({ translations, chosenLanguage }) {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTranslation, setSelectedTranslation] = useState(null);

    const { executeFetch } = useFetch();

    const openDeleteModal = (translation) => {
        setSelectedTranslation(translation);
        setIsDeleteModalOpen(true);
    };

    const openEditModal = (translation) => {
        setSelectedTranslation(translation);
        setIsEditModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleDelete = (translation) => {
        openDeleteModal(translation);
    };

    const handleEdit = (translation) => {
        openEditModal(translation);
    };

    const deleteTranslation = () => {
        const { key } = selectedTranslation;
        fetch(`https://api.digi-youth.com/translations/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                translationKey: key,
                language: chosenLanguage,
            }),
        }).then((response) => {
            if (response.status === 200) {
                alert('Translation deleted successfully');
                // Refresh the translations

                fetch(`https://api.digi-youth.com/translations/get?language=${chosenLanguage ? chosenLanguage : 'en'}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
            } else {
                alert('Something went wrong');
            }
        })

        closeDeleteModal();
    };

    const editTranslation = () => {
        const key = keyInputRef.current.value;
        const value = valueInputRef.current.value;
        fetch(`https://api.digi-youth.com/translations/edit`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                translationKey: key || selectedTranslation.key,
                translationValue: value,
                language: chosenLanguage,
            }),
        })
        closeEditModal();
    };

    function ColumnFilter({ column }) {
        const { filterValue, setFilter } = column;

        return (
            <input
                value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={`Filter ${column.render('Header')}`}
            />
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Translation Key',
                accessor: 'key', // Specify the key property from the translations object
                Filter: ColumnFilter,
            },
            {
                Header: 'Translation Value',
                accessor: 'value', // Specify the value property from the translations object
                Filter: ColumnFilter,
            },
            {
                Header: 'Delete',
                accessor: 'delete', // Placeholder accessor
                Cell: ({ row }) => (
                    <Button variant="outlined" onClick={() => handleDelete(row.original)}>
                        Delete
                    </Button>
                ),
            },
            {
                Header: 'Edit',
                accessor: 'edit', // Placeholder accessor
                Cell: ({ row }) => (
                    <Button variant="outlined" onClick={() => handleEdit(row.original)}>
                        Edit
                    </Button>
                ),
            },
        ],
        []
    );

    const data = React.useMemo(
        () =>
            Object.entries(translations).map(([key, value]) => ({
                key,
                value,
            })),
        [translations]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // Pagination related properties and methods
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, globalFilter },
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const keyInputRef = useRef(null);
    const valueInputRef = useRef(null);

    return (
        <>
            <input
                type="text"
                value={globalFilter || ""}
                onChange={e => setGlobalFilter(e.target.value)}
            />
            <table {...getTableProps()} className="react-table">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} key={column.id}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} key={row.id}>
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} key={cell.column.id}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {/* Pagination */}
            <div>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </button>
                <span>
          Page{' '}
                    <strong>
            {pageIndex + 1}
          </strong>{' '}
        </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </button>
            </div>

            <StyledModal open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <ModalContent>
                    <h2>Delete Translation</h2>
                    <p>Are you sure you want to delete this translation?</p>
                    <Button variant="contained" color="error" onClick={deleteTranslation}>
                        Delete
                    </Button>
                    <Button variant="outlined" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                </ModalContent>
            </StyledModal>

            <StyledModal open={isEditModalOpen} onClose={closeEditModal}>
                <ModalContent>
                    <h2>Edit Translation</h2>
                    <p>Are you sure you want to edit this translation?</p>
                    <p>Key: <input ref={keyInputRef} placeholder={selectedTranslation?.key}></input></p>
                    <p>Value: <input ref={valueInputRef} placeholder={selectedTranslation?.value}></input></p>
                    <p>Language: {chosenLanguage}</p>
                    <Button variant="contained" color="primary" onClick={editTranslation}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={closeEditModal}>
                        Cancel
                    </Button>
                </ModalContent>
            </StyledModal>
        </>
    );
}

function AvailableLanguages() {
    const { data: availableLanguages} = useFetch('https://api.digi-youth.com/translations/languages/get', {
        method: 'GET',
    })

    return (
        <>
            Available languages:
            {availableLanguages?.languages?.map((language) => (
                <strong style={{ display: 'block', textTransform: 'uppercase' }} key={uuidv4()}>
                    {language}
                </strong>
            ))}
        </>
    )
}