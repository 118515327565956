import React, {useRef, useState} from 'react';
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
import Box from "@mui/material/Box";
import {TextField, Typography} from "@mui/material";
import {HeadingText} from "../../components/styles/Typography.styled";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useEffect} from "react";
import Paper from "@mui/material/Paper";
import {v4 as uuidv4} from "uuid";
import {ButtonStyled} from "../../components/styles/UserButtons.styled";
import {deleteNewsByUrlPath, addNews, listNews} from "../../utils/api/news";
import {FlexContainer} from "../../components/styles/Containers.styled";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminPanelNews() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <LeftNavigation/>
            <AdminRoot>
                <HeadingText variant="h5">News</HeadingText>
                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="List News" {...a11yProps(0)} />
                            <Tab label="Add News" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ListNews />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AddNews />
                    </TabPanel>
                </Box>
            </AdminRoot>
        </>
    )
}

function ListNews() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        listNews().then((res) => {
            const { news } = res
            console.log(news)
            setNews(news)
        })
    }, [])

    const handleDelete = async (urlPath) => {
        await deleteNewsByUrlPath(urlPath)
    }

    return (
        <>
            {news.map((item) => (
                <Paper sx={{ mb: 5, width: '50%', p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }} elevation={1} key={uuidv4()}>
                    <div>Title:</div>
                    <strong>{item.heading}</strong>
                    <div>Preview text:</div>
                    <strong>{item.previewText}</strong>
                    <img src={item.imgPath} alt={item.heading} style={{ width: '200px', height: '200px', marginTop: '1rem' }}/>
                    <ButtonStyled onClick={() => handleDelete(item.urlPath)} sx={{ mt: 2, backgroundColor: '#f50057', '&:hover': {backgroundColor: 'red',} }}>Delete</ButtonStyled>
                </Paper>
            ))}
        </>
    )
}

function AddNews() {
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imgPath, setImgPath] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const headingRef = useRef(null);
    const previewTextRef = useRef(null);
    const urlPathRef = useRef(null);
    const descriptionRef = useRef(null);
    const uploadImageRef = useRef(null);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://api.digi-youth.com/news/image/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('Image uploaded successfully!');
                const { imagePath } = await response.json();
                setImgPath(imagePath)
                console.log(imagePath)
                setIsImageUploaded(true)
            } else {
                console.error('Error uploading image:', response.statusText);
                setIsImageUploaded(false)
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleAddNews = async () => {
        if(!setIsImageUploaded) return alert('Something went wrong! Image was not uploaded...')

        const heading = headingRef.current.value;
        const previewText = previewTextRef.current.value;
        const urlPath = urlPathRef.current.value;
        const description = descriptionRef.current.value;
        const imagePath = `https://api.digi-youth.com/public${imgPath}`

        addNews(heading, previewText, urlPath, description, imagePath).then((res) => {
            if (res.success) {
                alert('News added successfully!')
                headingRef.current.value = '';
                previewTextRef.current.value = '';
                urlPathRef.current.value = '';
                descriptionRef.current.value = '';
                uploadImageRef.current.value = '';
                setIsImageUploaded(false)
                setImgPath('')

            } else if (res.error && res.error.errors) {
                const errorMessages = Object.values(res.error.errors).map(error => error.message);
                setErrorMessage(errorMessages.join(', '));
            }
        })

    }

    return (
        <FlexContainer sx={{ width: '50%' }}>
            <TextField inputRef={headingRef} label="Heading" variant="outlined" />
            <TextField inputRef={previewTextRef} label="Preview text" variant="outlined" />
            <TextField inputRef={urlPathRef} label="URL Path" variant="outlined" />
            <TextField inputRef={descriptionRef} label="Description" multiline rows={4} variant="outlined" />
            <input ref={uploadImageRef} type="file" accept="image/*" onChange={handleImageUpload} />
            <ButtonStyled sx={{ mt: 2 }} onClick={() => handleAddNews()}>Add</ButtonStyled>
            {errorMessage && <strong style={{ color: 'red' }}>{errorMessage}</strong>}
        </FlexContainer>
    )
}

